import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ThemeController } from '@embracesbs/helpers'
import { MsalProvider } from '@azure/msal-react'
import { Configuration, PublicClientApplication } from '@azure/msal-browser'
import Loader from './Components/Loader/Loader'
import { ConfigProvider, useConfig } from './Components/Config/ConfigContext'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const Root = () => {
  return (
    <React.StrictMode>
      <ConfigProvider>
        <MsalInstanceProvider>
          <ThemeController>
            <App />
          </ThemeController>
        </MsalInstanceProvider>
      </ConfigProvider>
    </React.StrictMode>
  )
}

function MsalInstanceProvider({ children }: { children: React.ReactNode }) {
  const [msalConfig, setMsalConfig] = useState<Configuration | null>(null)
  const config = useConfig()

  useEffect(() => {
    if (!config) return
    const loadConfig = async () => {
      const msalConfig: Configuration = {
        auth: {
          clientId: config.authClientId,
          authority: config.authAuthority,
          redirectUri: window.location.origin
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: false
        },
        system: {
          allowNativeBroker: false // Disables WAM Broker
        }
      }
      setMsalConfig(msalConfig)
    }
    loadConfig()
  }, [config])

  if (!msalConfig) {
    // Show a loading spinner or fallback UI
    return (
      <ThemeController>
        <Loader ringCollor='lightgrey' viewbox='-75 10 200 50' text='Loading configuration'></Loader>
      </ThemeController>
    )
  }

  const msalInstance = new PublicClientApplication(msalConfig)
  msalInstance.initialize()

  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>
}

root.render(<Root />)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
