export interface Subscription {
  customerName: string
  certFinished: boolean
  id: string
  isDeleted: boolean
  creationTime: string
  createdBy: string
  modifiedTime: string
  modifiedBy: string
}

export interface PostSubscription {
  customerName: string
}

export interface Tenant {
  tenantName: string
  tenantDisplayname: string
  environment: string
  subscriptionId: string
  vaultUri: string
  azureDevopsBuildDefinitionId: number
  azureDevopsLastRunUri: string
  activeReleaseLock: boolean
  deployStatus: DeployStatus
  id: string
  isDeleted: boolean
  creationTime: string
  createdBy: string
  modifiedTime: string
  modifiedBy: string
}

export type DeployStatus =
  | 'notCreated'
  | 'initializing'
  | 'initializingFailed'
  | 'initialized'
  | 'updating'
  | 'deleting'
  | 'updatingFailed'
  | 'pendingDelete'
  | 'finished'
  | 'unknown'
  | 'feWaiting'
  | 'supportLoading'

export interface PostTenant {
  tenantName: string
  tenantDisplayname: string
  environment: string
}

export interface SubscriptionWithTenants {
  subscription: Subscription
  tenants: Tenant[]
}

export interface TenantConfig {
  feRunMode: 'Full' | 'NoCustomers' | 'CustomersOnly'
  feHostname: string
  feLanguage: string
  feLanguageCode: string
  fePortalUrl: string
  suiteVersion: string
  supportPassword: string
  gateway1CustomerAplicationId: string
  gateway1CustomerHostname: string
  gateway1Whitelisting: string
  customersUIHostname: string
  customersBackendPortForward: string
  customersMailEnabled: boolean
  customersChatEnabled: boolean
  customersTelephonyEnabled: boolean
  customersTasksEnabled: boolean
  customersCaseflowEnabled: boolean
  customersDossiersEnabled: boolean
  customersPhoneHealthCheckEnabled: boolean
  customersDisableChatNotifications: boolean
  queryConfigFromGraph: boolean
  enableCMSPackages: boolean
  socialDashboardId: string
  socialTeamId: string
  socialThemeId: string
  matomoInteralHostname: string
  matomoInteralSiteId: number
  matomoInternalContainerId: string
  matomoExternalHostname: string
  matomoExternalSiteId: number
  socialContentMigrationEnabled: boolean
  authenticationType: string
  clientId: string
  clientSecret: string
  idpAzureAdTenantId: string
  idpAzureHostname: string
  idpSkipKeycloakLoginPage: boolean
  idpDomainHint: string
  idpOverrideBrowserFlow: boolean
  idpWebcenterEnabled: boolean
  idpWebcenterHostname: string
  idpWebcenterClientId: string
  idpWebcenterClientSecret: string
  idpCustomersPortalsEnabled: boolean
  idpCustomersPortalsHostname: string
  idpCustomersPortalsClientId: string
  idpCustomersPortalsClientSecret: string
  portalWidgetPacks: string
  helloIdEnabled: boolean
  helloIdClientId: string
  helloIdClientSecret: string
  helloIdTenantId: string
  publicPortalThemingEnabled: boolean
  useLatestRouter: boolean
  sharedPaymentsProvider: string
  sharedPaymentsProviderConfiguration: string
  liquitUrl: string
  appendEmbraceIdpScope: boolean
  customScopes: string
}

export interface FeatureFlags {
  name: string
  enabled: boolean
  label: string
}
export interface Audit {
  action: {
    traceId: string
    httpMethod: 'GET' | 'POST' | 'PUT' | 'DELETE'
    userName: string
    actionParameters: {
      tenantid: string
    }
  }
  startDate: string
  eventType: string
  id: string
  isDeleted: boolean
  creationTime: string
  createdBy: string
  modifiedTime: string
  modifiedBy: string
}

export interface BaseConfig {
  host: string
}

export interface EmbraceTenantsConfiguration {
  host: string
  authClientId: string
  authAuthority: string
  authScope: string
  environments: string[]
}

export interface Notification {
  severity: number
  starttime: string
  endtime: string
  content: NotificationContent
}

export interface NotificationContent {
  en: {
    main: string
    title: string
  }
  nl: {
    main: string
    title: string
  }
}

export interface NotificationsState {
  featureFlags: Audit[]
  featureFlagsLoaded: boolean
  notifications: Notification[]
  value?: {
    from: Date
    to: Date
  }
  newNotification: {
    severity: number
    starttime: string
    endtime: string
    content: string
  }
}

export interface MaintenanceType {
  hasMaintenance: boolean
  startTime?: string
  endTime?: string
  url?: string
  key?: string
  title?: {
    en?: string
    nl?: string
  }
  description?: {
    en?: string
    nl?: string
  }
}

export interface NewMaintenance {
  startTime: string
  endTime: string
  title: string
  description: string
}

export interface DateRange {
  from: Date
  to: Date
}

export interface BreadcrumbsData {
  trigger: boolean
  data: {
    subscriptionId?: string
  }
}

export interface FeatureFlag {
  label: string
  name: string
  enabled: boolean
  enabledFor?: string[]
}

export const PRIORITY_INFO = 1
export const PRIORITY_NORMAL = 3
export const PRIORITY_HIGH = 5

export enum TabId {
  DeploytimeSetting = 1,
  FeatureFlags = 2,
  Maintenance = 3,
  Notifications = 4,
  Monitoring = 5,
  AuditLog = 6,
  Settings = 7
}
