import React from 'react'
import Text from '@embracesbs/component-text'
import { Tenant, Subscription } from '../../Interfaces/Types'
import { Link } from 'react-router-dom'

interface TenantDetailsProps {
  selectedTenant?: Tenant
  selectedSubscription?: Subscription
  onSelectedSubscription: (subscriptionId: string) => void
  onSelectedTenant: (tenant: Tenant | undefined) => void
}

const Breadcrumbs: React.FC<TenantDetailsProps> = (props) => {
  const linkStyle = {
    cursor: 'pointer'
  }

  return (
    <Text>
      <div>
        <Link to={`/subscriptions`} style={{ textDecoration: 'none' }}>
          <span
            className='breadcrumb-link'
            style={linkStyle}
            onClick={() => {
              props.onSelectedTenant(undefined)
              props.onSelectedSubscription('')
            }}
          >
            Subscriptions /{' '}
          </span>
        </Link>
        <Link to={`/subscriptions/${props.selectedSubscription?.id}`} style={{ textDecoration: 'none' }}>
          <span
            className='breadcrumb-link'
            style={linkStyle}
            onClick={() => {
              props.onSelectedTenant(undefined)
              props.onSelectedSubscription(props.selectedSubscription?.id || '')
            }}
          >
            {props.selectedSubscription?.customerName} /{' '}
          </span>
        </Link>
        <Link to={`/subscriptions/${props.selectedSubscription?.id}/tenant/${props.selectedTenant?.id}`} style={{ textDecoration: 'none' }}>
          <span className='breadcrumb-link' style={linkStyle} onClick={() => props.onSelectedTenant(props.selectedTenant!)}>
            {props?.selectedTenant?.tenantDisplayname}
          </span>
        </Link>
      </div>
    </Text>
  )
}

export default Breadcrumbs
