import Text from '@embracesbs/component-text'
import { useEffect, useState, useRef } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useApiCall } from '../../Utilities/ApiService/ApiService'
import Button2 from '@embracesbs/component-button2/Button2'
import { Tenant } from '../../Interfaces/Types'
import { useAccessToken } from '../../Utilities/Auth/AuthService'

const style = {
  backgroundColor: '#fff3cd', // Light yellow warning background
  padding: '20px',
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)', // Soft shadow for subtle depth
  border: '1px solid #ffeeba', // Light warning border
  fontFamily: 'Arial, sans-serif',
  color: '#856404' // Warning text color
}

const alertStyle = {
  backgroundColor: 'rgb(255 255 255 / 35%)', // Light yellow for warning
  padding: '10px',
  border: '1px solid #ffeeba', // Light warning border
  color: '#856404', // Dark amber for readable text
  marginTop: '15px',
  fontWeight: 'bold', // Emphasize warning text
  borderRadius: '8px' // Rounded corners for a softer look
}

const headingStyle = {
  margin: '0 15px 0 0',
  fontSize: '24px',
  fontWeight: 'bold',
  color: '#000000'
}

const subHeadingStyle = {
  margin: '0 15px 0 0',
  fontSize: '18px',
  color: '#856404' // Consistent warning color
}

const buttonStyle = {
  marginTop: '20px' // Increased spacing between text and button
}

export default function DeleteTenant() {
  const { callApiWithToken } = useApiCall()
  const accessToken = useAccessToken()
  // tenant
  const [tenantError, setTenantError] = useState<Error | null>(null)
  const [tenantLoaded, setTenantLoaded] = useState(false)
  const [tenant, setTenant] = useState<Tenant>()

  // other
  const [refresh, setRefresh] = useState(0)
  const backgrondRefresh = useRef(false)
  let params = useParams()
  let navigate = useNavigate()

  useEffect(() => {
    const abortController = new AbortController()

    if (backgrondRefresh.current) {
      // do background refresh only this request
      backgrondRefresh.current = false
    } else {
      setTenantLoaded(false)
      setTenantError(null)
    }
    fetchTenantDetails(abortController)

    return () => {
      abortController.abort()
    }
  }, [params.tenantId, refresh, accessToken])

  const fetchTenantDetails = (abortController: AbortController) => {
    callApiWithToken({
      endpoint: `/api/tenant/${params.tenantId}`,
      method: 'GET',
      body: {
        signal: abortController.signal
      }
    })
      .then(function (response) {
        setTenant(response)
        setTenantLoaded(true)
      })
      .catch(function (error) {
        console.log(error)
        if (!abortController.signal.aborted) {
          setTenantError(error)
        }
      })
  }

  useEffect(() => {
    if (tenant && tenant.activeReleaseLock) {
      const timeout = setTimeout(() => {
        backgrondRefresh.current = true
        setRefresh((r) => r + 1)
      }, 5000)
      return () => {
        clearTimeout(timeout)
      }
    }
  }, [tenant])

  const handleDelete = () => {
    return new Promise((reject) => {
      callApiWithToken({
        endpoint: `/api/tenant/${tenant?.id}`,
        method: 'DELETE'
      })
        .then(() => {
          navigate(`/subscriptions/${tenant?.subscriptionId}`, { replace: true })
        })
        .catch((error) => {
          console.log(error)
          reject(error.message)
        })
    })
  }

  if (tenantError) {
    return <div>Error: {tenantError.message}</div>
  } else if (!tenantLoaded) {
    return (
      <center>
        <Text type='grow'>Loading</Text>
      </center>
    )
  } else {
    return (
      <div style={style}>
        <h1 style={headingStyle}>Display name: {tenant?.tenantDisplayname}</h1>
        <h4 style={subHeadingStyle}>Tenant name: {tenant?.tenantName}</h4>

        {tenant?.deployStatus === 'pendingDelete' && (
          <div style={alertStyle}>
            <h4 className='alert-heading'>You are about to delete '{tenant?.tenantName}'</h4>
            <Button2 style={buttonStyle} text='Delete' isDanger onClick={handleDelete}></Button2>
          </div>
        )}
        {tenant?.deployStatus !== 'pendingDelete' && (
          <div style={alertStyle}>
            <h4 className='alert-heading'>You are not able to delete '{tenant?.tenantName}'</h4>
            {tenant?.deployStatus !== 'deleting' && (
              <h4 className='alert-heading'>Make sure to first mark the tenant for deletion from the detail page!</h4>
            )}
            {tenant?.deployStatus === 'deleting' && <h4 className='alert-heading'>The tenant is being deleted already!</h4>}
          </div>
        )}
      </div>
    )
  }
}
