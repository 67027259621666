import React, { useEffect } from 'react'
import Text from '@embracesbs/component-text'
import { Tenant } from '../../Interfaces/Types'
import Switch from '@embracesbs/component-switch'
import Table from '@embracesbs/component-table'
import TableRow from '@embracesbs/component-tablerow'
import { FeatureFlags } from '../../Interfaces/Types'
import { useApiCall } from '../../Utilities/ApiService/ApiService'
import { sortByLabel } from '../../Utilities/helpers/helpers'
import { useAccessToken } from '../../Utilities/Auth/AuthService'

const style = {
  maxHeight: '90%', // Ensures that the div doesn't grow beyond the outer div's height
  overflow: 'auto', // Enables scrolling
  paddingRight: '15px'
}

interface TenantDetailsProps {
  tenant?: Tenant
}

const FeatureFlag: React.FC<TenantDetailsProps> = ({ tenant }) => {
  const [featureFlags, setFeatureFlags] = React.useState<FeatureFlags[]>([])
  const { callApiWithToken } = useApiCall()
  const accessToken = useAccessToken()

  const toggleFeatureFlag = (name: string) => {
    const updatedFlags = featureFlags.map((flag) => {
      if (flag.name === name) {
        return { ...flag, enabled: !flag.enabled }
      }
      return flag
    })

    callApiWithToken({
      endpoint: `/api/appconfig/${tenant?.id}/featureflags`,
      method: 'PUT',
      body: updatedFlags
    })
      .then((data) => {
        setFeatureFlags(data.sort(sortByLabel))
      })
      .catch((error) => {
        console.error(error)
      })
  }

  useEffect(() => {
    fetchFeatureFlags()
  }, [tenant, accessToken])

  const fetchFeatureFlags = async () => {
    try {
      const response = await callApiWithToken({
        endpoint: `/api/appconfig/${tenant?.id}/featureflags`,
        method: 'GET'
      })
      if (response !== undefined && response.length > 0) {
        setFeatureFlags(response.sort(sortByLabel))
      }
    } catch (error) {
      alert(error)
      console.error(error)
    }
  }

  if (!tenant) {
    return (
      <>
        <Text align='center' textColor='grey'>
          Please select a tenant
        </Text>
      </>
    )
  }

  return (
    <div style={style}>
      <Table>
        {featureFlags.map((item, index) => (
          <TableRow key={index}>
            <Switch
              name={item.name}
              label={item.name}
              value='a'
              isChecked={item.enabled}
              onClick={() => {
                toggleFeatureFlag(item.name)
              }}
            />
            <Text>{item.label}</Text>
          </TableRow>
        ))}
      </Table>
    </div>
  )
}

export default FeatureFlag
