import React, { useEffect, useState } from 'react'
import Text from '@embracesbs/component-text'
import { Audit, Tenant } from '../../Interfaces/Types'
import Table from '@embracesbs/component-table'
import TableRow from '@embracesbs/component-tablerow'
import { useApiCall } from '../../Utilities/ApiService/ApiService'
import { useAccessToken } from '../../Utilities/Auth/AuthService'

interface TenantDetailsProps {
  tenant?: Tenant
}

const style = {
  maxHeight: '50%', // Ensures that the div doesn't grow beyond the outer div's height
  overflow: 'auto' // Enables scrolling
}

const AuditLog: React.FC<TenantDetailsProps> = ({ tenant }) => {
  const { callApiWithToken } = useApiCall()
  const [audits, setAudits] = useState<Audit[]>([])
  const accessToken = useAccessToken()

  useEffect(() => {
    fetchAudits()
  }, [tenant, accessToken])

  const fetchAudits = async () => {
    try {
      const response = await callApiWithToken({
        endpoint: `/api/audit/${tenant?.id}`,
        method: 'GET'
      })
      if (response !== undefined && response.length > 0) {
        setAudits(response)
      }
    } catch (error) {
      alert(error)
      console.error(error)
    }
  }

  if (!tenant) {
    return (
      <Text align='center' textColor='grey'>
        Please select a tenant
      </Text>
    )
  }

  return (
    <div style={style}>
      <Table>
        {audits?.map((item) => (
          <TableRow key={item.id}>
            <Text>{item.startDate}</Text>
            <Text>{item.action.userName}</Text>
            <Text>Tenant Updated</Text>
          </TableRow>
        ))}
      </Table>
    </div>
  )
}

export default AuditLog
