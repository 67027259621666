import React, { useState } from 'react'
import { Tenant } from '../../Interfaces/Types'
import Button2 from '@embracesbs/component-button2/Button2'
import Icon from '@embracesbs/component-icon/Icon'
import { ApiCallOptions } from '../../Utilities/ApiService/ApiService'
import Text from '@embracesbs/component-text'

interface SupportLoginQuickButtonDetailsProbs {
  tenant?: Tenant
  updateTenant: (tenant: Tenant) => void
  callApiWithToken: ({ endpoint, method, body, additionalHeaders, token }: ApiCallOptions) => Promise<any>
}

//Those full urls should come from backend and not hardcoded
const SupportLoginQuickButton: React.FC<SupportLoginQuickButtonDetailsProbs> = ({ tenant, updateTenant, callApiWithToken }) => {
  const handleSupportLogin = () => {
    let supportUrl = ''
    if (tenant !== undefined) {
      tenant.deployStatus = 'supportLoading'
      updateTenant(tenant)
    }
    callApiWithToken({
      endpoint: `/api/tenant/${tenant?.id}/tenantsconfiguration/supporturl`,
      method: 'GET'
    })
      .then((data) => {
        switch (tenant?.environment) {
          case 'production':
            supportUrl = `https://auth.embracecloud.nl`
            break
          case 'beta':
            supportUrl = `https://auth.beta.embracecloud.nl`
            break
          default:
            supportUrl = `https://auth.${tenant?.environment}.embracecloud.io`
        }
        supportUrl += `/auth/realms/${tenant?.tenantName}/protocol/openid-connect/auth?client_id=embracecloud&redirect_uri=${data}&response_type=code&scope=openid+suite-user+identity-provider-user&kc_idp_hint=support`
        window.open(supportUrl, '_blank', 'noopener,noreferrer')
        if (tenant !== undefined) {
          tenant.deployStatus = 'finished'
          updateTenant(tenant)
        }
      })
      .catch((error) => {
        if (tenant !== undefined) {
          tenant.deployStatus = 'finished'
          updateTenant(tenant)
        }
        console.error(error)
      })
  }

  if (!tenant) {
    return (
      <>
        <Text>There is no tenant selected</Text>
      </>
    )
  }

  return (
    <>
      {tenant.deployStatus === 'finished' || tenant.deployStatus === 'supportLoading' ? (
        <Button2
          className='launch-button'
          buttonSize='30px'
          leftElement={<Icon className='launch-icon' iconName='HeadphonesCustomerSupportFilled' size={10} />}
          tabIndex={-1}
          onClick={handleSupportLogin}
          isLoading={tenant.deployStatus === 'supportLoading'}
          text=''
          tooltip='Open support login'
          type='button'
          variant='subtle'
        />
      ) : null}
    </>
  )
}

export default SupportLoginQuickButton
